/** @jsxImportSource @emotion/react */
import { Link, navigate } from '@reach/router';
import instance, { isErrorHandled, useFetch } from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { plaintext } from 'plaintext.config';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCustomerComponentResources,
} from 'reducers/componentResources';

import FillerImage from 'assets/ibanera/IbaneraBounce.gif';
import { css, useTheme } from '@emotion/react';
import { Toast, ToastMessageReason } from 'helpers/toast';
import * as Yup from 'yup';
import { getErrorMessage } from 'errors';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { Spinner } from 'components/spinner/Spinner';
import { FormBuilder } from 'components/form/FormBuilder';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import { ModalTypes, openModal, PendingCard } from 'reducers/modal';
import { FormFieldRendererOverride } from '../../components/categoryComponents/Entity/Entity';
import { ShippingMethod } from './formRenderers/ShippingMethod';
import ESignModal from 'components/e-sign/ESignModal';
import { ThreeBounce } from 'better-react-spinkit';
import { cloneDeep } from 'lodash';

type FormData = {
    businessActivityType?: string;
    complianceFieldOptions: any;
    currency?: string;
    customerSubtype?: string;
    occupationType?: string;
    sourceOfFundsType?: string;
    taxNumber?: string;
    shippingMethod: string;
    programType?: string;
};

const validationSchema = Yup.object({
    currency: Yup.string()
        .typeError('Please select a currency')
        .required('Please select a currency'),
    customerSubtype: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    businessActivityType: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    occupationType: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    sourceOfFundsType: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    taxNumber: Yup.string().required('Please enter your tax number'),
});

const overrideFormFieldRenderer: FormFieldRendererOverride<FormData> = {
    shippingMethod: (_key, field) => <ShippingMethod field={field} />,
};

export const AddCard = () => {
    const theme = useTheme();
    const [formValues, setFormValues] = useState<FormData | null>(null);
    const [submitingRequest, setSubmitingRequest] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const addAccountCss = css({ h3: { color: theme.colors.second } });
    const dispatch = useDispatch();

    const translations = plaintext.addCardPage;

    const { loading, error, data } = useFetch<ApiResponse<FormData>>(endpoints.cards.addCard);

    const handleSubmit = useCallback((values: FormData, formikHelpers: FormikHelpers<FormData>) => {
        setErrorMessage('');
        formikHelpers.setSubmitting(false);
        if (values.currency === undefined || values.currency === '') {
            setErrorMessage('Please select a currency');
            return;
        } else if (values.programType === undefined || values.programType === '') {
            setErrorMessage('Please select a program type');
            return;
        }
        setFormValues(values);
        // for(let item of data?.metadata ?? []){
        //     if(item.bRequired){
        //         if(values[item.name] == "" ){
        //         }
        //     }
        // }
    }, []);

    const handleConcentAccepted = useCallback(() => {
        setSubmitingRequest(true);

        const valuesToPost = cloneDeep(formValues);
        setFormValues(null);
        instance
            .post<ApiResponse<{ cardUrlPath: string }>>(
                endpoints.cards.addCard /* 'localhost:1234' */,
                valuesToPost
            )
            .then((res) => {
                setSubmitingRequest(false);
                if (res?.data?.details?.bReadyForActivation) {
                    dispatch(getCustomerComponentResources());
                    return navigate(`../${res.data.details.cardUrlPath}`);
                } else {
                    const newPendingCard: PendingCard = {
                        id: res?.data?.details?.topUpCardsId,
                        cardUrlPath: res?.data?.details?.cardUrlPath,
                        bReadyForActivation: res?.data?.details?.bReadyForActivation,
                    };

                    dispatch(
                        openModal({ modalType: ModalTypes.PENDING_CARD, data: newPendingCard })
                    );
                }
            })
            .catch((err) => {
                setSubmitingRequest(false);
                if (isErrorHandled(err)) {
                    let errorToast = false;
                    //iterate through the errors returned by the api and set error messages on the appropriate fields
                    err.response.data.errors.forEach((error) => {
                        const fieldName = toCamelCase(error.fieldName) as string;
                        if (Object.keys(valuesToPost ?? {}).includes(fieldName)) {
                            setErrorMessage(getErrorMessage(error.messageCode));
                        } else if (!errorToast) {
                            errorToast = true;
                            Toast.openToastMessage(
                                getErrorMessage(error.messageCode),
                                ToastMessageReason.ERROR
                            );
                        }
                    });
                } else {
                    Toast.openGenericErrorToast();
                }
            });
    }, [dispatch, formValues]);


    const fieldsMapped = data?.metadata.reduce((prev, field) => {
        prev[toCamelCase(field.name)] = field.type === 'String' ? '' : null;
        return prev;
    }, {} as { [key: string]: string | null });

    const initialValues = {
        ...(data?.details ?? {}),
        ...fieldsMapped,
    };

    return (
        <div className="AddAccountPage" css={addAccountCss}>
            <div className="LeftSide">
                <h3>{translations.addNewCard}</h3>
                <p>{translations.blurb}</p>
                {loading ? (
                    <Spinner />
                ) : (
                    data && (
                        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                            {({ isSubmitting }: FormikProps<any>) => (
                                <Form className="FormBuilderForm">
                                    <FormBuilder
                                        loadingFallback={<Spinner />}
                                        data={data}
                                        overrideFormFieldRenderer={overrideFormFieldRenderer}
                                    />

                                    <Button
                                        variety="full"
                                        type="submit"
                                        disabled={isSubmitting || submitingRequest}
                                    >
                                        {translations.continue}
                                    </Button>
                                    <p style={{ color: 'red', fontSize: 12, minHeight: 20 }}>
                                        {errorMessage && errorMessage}
                                    </p>
                                    {submitingRequest && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <ThreeBounce size={20} color={theme.colors.first} />
                                        </div>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    )
                )}
            </div>
            <img src={FillerImage} alt="" className="FillerImage" />

            {formValues && (
                <ESignModal
                    programType={formValues.programType}
                    onConcentAccepted={handleConcentAccepted}
                    close={() => {
                        setFormValues(null);
                    }}
                />
            )}
        </div>
    );
};
