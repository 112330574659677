import React, { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { applePayHelper } from '../helpers/applepay';
import { PaymentInfo } from 'pages/quickCoin/CardPaymentForm';
import { PurchaseDetails } from 'pages/quickCoin/model';

type Props = {
    onComplete: (res: PaymentInfo) => void;
    basket: PurchaseDetails;
    deviceSessionId: string;
    idempotencyKey: string;
};

export const ApplePayButton = ({ onComplete, basket, deviceSessionId, idempotencyKey }: Props) => {
    const [applePayButton, setApplePayButton] = useState<HTMLElement | null>(null);
    useEffect(() => {
        applePayHelper.getButtonWhenReady.then((btn) => setApplePayButton(btn));
    }, []);

    useEffect(() => {
        applePayHelper.setIdempotencyKey(idempotencyKey);
    }, [idempotencyKey]);

    useEffect(() => {
        applePayHelper.updateOnComplete(onComplete);
    }, [onComplete]);

    useEffect(() => {
        applePayHelper.setValues({
            totalCost: basket.price?.toString() ?? '0.00',
            currencyCode: 'EUR', //basket.currency,
        });
    }, [basket.currency, basket.price]);
    return (
        <div
            id="applePayContainer"
            ref={(ref) => applePayButton && ref?.appendChild(applePayButton)}
        ></div>
    );
};
