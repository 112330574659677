/**
 * Reducer paired with the notifications system. 'ApplicationDataPush' message
 * updates the value in this reducer. Components subscribe to the value in this
 * reducer and if the value indicates the component should update, it does so.
 * If your application doesn't use the notifications system to send messages
 * other than stack notifications there is no need to include this in your app.
 */
import { JumioVerificationStatusResponse } from 'components/jumio/jumioModels';
import { BusinessVerificationStatusResponse } from 'components/businessVerification/businessVerificationModels';
import { AccountLink, ElementType } from 'components/sideMenu/SideMenu';
import { AssetPairPrice } from 'reducers/cryptoPrices';
import { Store } from '../../reducers/rootReducer';
import { NoteData } from 'components/modals/TransactionNoteModal';

// Identifiers can be found in Stoplight project under dummy notifications endpoint.
export enum NotificationIdentifier {
    UPDATE_ASSET_PRICES = 'UPDATE_ASSET_PRICES',
    RELOAD_PORTFOLIO_TABLE = 'RELOAD_PORTFOLIO_TABLE',
    RELOAD_TRANSACTIONS_TABLE = 'RELOAD_TRANSACTIONS_TABLE',
    RELOAD_HISTORY_TABLE = 'RELOAD_HISTORY_TABLE',
    RELOAD_NAVIGATION_LINKS = 'RELOAD_NAVIGATION_LINKS',
    CLOSE_DALPAY_IFRAME = 'CLOSE_DALPAY_IFRAME',
    UPDATE_VERIFICATION_COMPONENTS = 'UPDATE_VERIFICATION_COMPONENTS',
    UPDATE_ACCOUNT_BALANCE = 'UPDATE_ACCOUNT_BALANCE',
    MERGE_ACCOUNT = 'MERGE_ACCOUNT',
    UPDATE_TRANSACTION = 'UPDATE_TRANSACTION',
    ADD_TRANSACTION_NOTE = 'ADD_TRANSACTION_NOTE',
    UPDATE_HISTORY_TRANSACTION = 'UPDATE_HISTORY_TRANSACTION',
    RELOAD_BUSINESS_DOCUMENT_LIST = 'RELOAD_BUSINESS_DOCUMENT_LIST',
    RELOAD_PAYEES_TABLE = 'RELOAD_PAYEES_TABLE',
    QUICKCRYPTO_CARD_PAYMENT_UPDATED = 'QUICKCRYPTO_CARD_PAYMENT_UPDATED',
    BUSINESS_VERIFIED = 'BUSINESS_VERIFIED',
    BUSINESS_DOCUMENT_VERIFIED = 'BUSINESS_DOCUMENT_VERIFIED',
    RELOAD_MANAGEE_VERIFICATIONS = 'RELOAD_MANAGEE_VERIFICATIONS',
    PAYEE_CARD_CREATED = 'PAYEE_CARD_CREATED',
    INFORMATION_REQUEST_UPDATED = 'INFORMATION_REQUEST_UPDATED',
    INFORMATION_REQUESTED = 'INFORMATION_REQUESTED',
    INFORMATION_REMINDER = 'INFORMATION_REQUEST_REMINDER',
    CARD_CREATE_SUCCEEDED = 'CARD_CREATE_SUCCEEDED',
    QUICKSELL_DEPOSIT_SUCCEEDED = 'QUICKSELL_DEPOSIT_SUCCEEDED',
}

export type NotificationData =
    | {
          pushType: NotificationIdentifier.UPDATE_ASSET_PRICES;
          data: {
              prices: AssetPairPrice[];
          };
      }
    | {
          pushType: NotificationIdentifier.RELOAD_HISTORY_TABLE;
      }
    | {
          pushType: NotificationIdentifier.RELOAD_NAVIGATION_LINKS;
      }
    | {
          pushType: NotificationIdentifier.RELOAD_PORTFOLIO_TABLE;
      }
    | {
          pushType: NotificationIdentifier.RELOAD_TRANSACTIONS_TABLE;
      }
    | {
          pushType: NotificationIdentifier.ADD_TRANSACTION_NOTE;
          data: NoteData;
      }
    | {
          pushType: NotificationIdentifier.RELOAD_BUSINESS_DOCUMENT_LIST;
      }
    | {
          pushType: NotificationIdentifier.BUSINESS_VERIFIED;
          data: {
              status: 'Success' | 'Rejected';
              additionalData: { businessName: string; customerUsersCustomersId: number };
          };
      }
    | {
          pushType: NotificationIdentifier.BUSINESS_DOCUMENT_VERIFIED;
          data: {
              status: 'Success' | 'Rejected';
              additionalData: {
                  businessName: string;
                  documentName: string;
                  rejectReason: string;
                  customerBusinessDocumentId: number;
              };
          };
      }
    | {
          pushType: NotificationIdentifier.UPDATE_ACCOUNT_BALANCE;
          data: { customerAssetAccountsId: number; balance: number };
      }
    | {
          pushType: NotificationIdentifier.CLOSE_DALPAY_IFRAME;
          data: { bVoid: boolean; guid: string };
      }
    | {
          pushType: NotificationIdentifier.UPDATE_VERIFICATION_COMPONENTS;
          data: JumioVerificationStatusResponse | BusinessVerificationStatusResponse;
      }
    | {
          pushType: NotificationIdentifier.PAYEE_CARD_CREATED;
      }
    | {
          pushType: NotificationIdentifier.UPDATE_TRANSACTION;
          data: {
              customerAssetAccountsTransactionsId: number;
              bPending: boolean;
              bReserved: boolean;
          };
      }
    | {
          pushType: NotificationIdentifier.UPDATE_HISTORY_TRANSACTION;
          data: {
              customerAssetAccountsTransactionsId: number;
              bPending: boolean;
              bReserved: boolean;
              bFrozen: boolean;
          };
      }
    | {
          pushType: NotificationIdentifier.MERGE_ACCOUNT;
          data: { elementType: ElementType; account: AccountLink };
      }
    | {
          pushType: NotificationIdentifier.RELOAD_PAYEES_TABLE;
      }
    | { pushType: NotificationIdentifier.QUICKCRYPTO_CARD_PAYMENT_UPDATED; data: {} }
    | { pushType: NotificationIdentifier.INFORMATION_REQUESTED }
    | { pushType: NotificationIdentifier.INFORMATION_REQUEST_UPDATED }
    | { pushType: NotificationIdentifier.INFORMATION_REMINDER }
    | {
          pushType: NotificationIdentifier.QUICKSELL_DEPOSIT_SUCCEEDED;
          data: { quoteReference: string };
      }
    | { pushType: NotificationIdentifier.CARD_CREATE_SUCCEEDED };

/**
 * Only a single update is stored which means that if it is not consumed immediately
 * it could get overwritten by a future update. This is fine because if the update
 * is not consumed immediately it means the target component isn't rendered and when
 * it does get rerendered, the latest data will be fetched anyway. This approach
 * means that any data sent in a SignalR message must also be reflected in the REST
 * API call when it gets made.
 */
type NotificationUIUpdateState = {
    update: NotificationData | null;
};

const initialState: NotificationUIUpdateState = { update: null };

enum NotificationUIUpdateActionType {
    ADD_UI_UPDATE = 'ADD_UI_UPDATE',
    COMPLETE_UI_UPDATE = 'COMPLETE_UI_UPDATE',
}

type NotificationUIUpdateAction =
    | {
          type: NotificationUIUpdateActionType.ADD_UI_UPDATE;
          payload: NotificationData;
      }
    | { type: NotificationUIUpdateActionType.COMPLETE_UI_UPDATE };

const Reducer = (
    state = initialState,
    action: NotificationUIUpdateAction
): NotificationUIUpdateState => {
    switch (action.type) {
        case NotificationUIUpdateActionType.ADD_UI_UPDATE:
            return { update: action.payload };
        case NotificationUIUpdateActionType.COMPLETE_UI_UPDATE:
            return { update: null };
        default:
            return state;
    }
};

export default Reducer;

// Action creators

export const setUIUpdate = (payload: NotificationData): NotificationUIUpdateAction => {
    return {
        type: NotificationUIUpdateActionType.ADD_UI_UPDATE,
        payload: payload,
    };
};
export const completeUIUpdate = (): NotificationUIUpdateAction => ({
    type: NotificationUIUpdateActionType.COMPLETE_UI_UPDATE,
});

// Selectors
export const selectUIUpdate = (store: Store) => store.notificationUIUpdate.update;
