import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import instance from 'api';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { AppPath, DashboardPath } from 'appConstants';
import Page from 'components/page/Page';
import { getErrorMessage } from 'errors';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    autoLogin,
    checkResponse,
    clearAuthMessage,
    refreshAccessToken,
    saveResponse,
    selectAuthMessage,
    selectSignedIn,
} from 'reducers/auth';
import { selectComponentResources } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';

export type RedirectState = { redirectUrl: string };

export const DirectLogin: React.FC<RouteComponentProps> = () => {
    const { userInfo } = useSelector(selectComponentResources);
    const cultureCode = useSelector(selectCultureCode);
    const errorMessages = useSelector(selectAuthMessage);
    const signedIn = useSelector(selectSignedIn);
    const dispatch = useDispatch();
    const { search } = useLocation();

    const decodedToken = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        try {
            return JSON.parse(atob(searchParams.get('token') ?? '')) ?? null;
        } catch {
            return null;
        }
    }, [search]);

    useEffect(() => {
        if (!decodedToken) return;
        if (decodedToken?.loginResponseDetails?.accessToken) {
            // call refresh endpoint to get new token
            const loginResponse = {
                details: decodedToken?.loginResponseDetails as any,
                status: '1',
            };

            saveResponse(loginResponse);
            dispatch(checkResponse(loginResponse));
            dispatch(refreshAccessToken([{ type: 'NULLACTION' }]));
        } else {
            // Token data is invalid navigate to login screen or dashboard
            Toast.openErrorToast(
                'There was an error logging you in. Please sign in again'
            );
            navigate('/');
        }
    }, [decodedToken, dispatch]);

    useEffect(() => {
        if (!decodedToken || (errorMessages && errorMessages.length > 0)) {
            Toast.openBlockingToast(
                'There was an error logging you in. Please sign in again',
                ToastMessageReason.ERROR
            );
            dispatch(clearAuthMessage());
            const redirectState: RedirectState = { redirectUrl: decodedToken?.redirectUrl };
            navigate('/', { state: redirectState });
        }
    });

    useEffect(() => {
        if (signedIn && userInfo && userInfo.accountType) {
            const accountTypePathItem =
                userInfo.accountType === 'Personal' ? AppPath.PERSONAL : AppPath.BUSINESS;
            if (decodedToken?.redirectUrl) {
                navigate(`/${cultureCode}${accountTypePathItem}${decodedToken?.redirectUrl}`);
            } else {
                navigate(
                    `/${cultureCode}${accountTypePathItem}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`
                );
            }
        }
    }, [signedIn, userInfo, cultureCode, decodedToken]);

    return <Page isPublic />;
};
